import React, { Component } from "react";
import { Box, Avatar, Grid, Button, InputLabel, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchBar from "../SearchBar";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import http from "../../../../http-common";
import CircularProgress from '@material-ui/core/CircularProgress';
import CookiesService from '../../../../services/CookiesService';
import Pagination from 'react-bootstrap/Pagination';
import NoDataFound from "../../../NoDataFound";


const styles = theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: "10%",
        },
    },
    small: {
        width: "10%",
        height: "10%",
    },
    large: {
        width: "200px",
        height: "200px"
    },
    sizeIcon: {
        fontSize: 50,
    },
});

class MyTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // pagination var
            totalCount: 0,
            isActive: true,
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            // pagination var
            userList: [],
            selectUser: [],
            selectedUsers: [],
            search: "",
            buttons: false,
            noDataFound: false,
        }
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmitIdea = this.handleSubmitIdea.bind(this);
        this.handleSubmitCoolInnovationIdea = this.handleSubmitCoolInnovationIdea.bind(this);
    }


    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    handleClick(e) {
        if (e.target.checked) {
            if (!this.state.selectUser.includes(e.target.value)) {
                this.state.selectUser = this.state.selectUser.concat(e.target.value);
                const selectedUsers = this.state.userList.filter(item => item.user_id == e.target.value);
                this.state.selectedUsers = this.state.selectedUsers.concat(selectedUsers);
            }
            this.setState({ buttons: true });
        }
        else {
            if (this.state.selectUser.includes(e.target.value)) {
                const selectedUsers = this.state.selectedUsers.filter(item => item.user_id != e.target.value);
                this.state.selectedUsers = selectedUsers;
                const users = this.state.selectUser.filter(item => item != e.target.value);
                this.state.selectUser = users;
            }
        }
        if (this.state.selectUser.length == 0) {
            this.setState({ buttons: false });
        }
    }

    callAPIOnPagination() {
        var data = CookiesService.get("userData");
        // let data = JSON.parse(sessionStorage.getItem("userData"));
        var params = {
            "company_id": data.company_id,
            "user_id": data.user_id,
            "page": this.state.currentPage,
            "per_page": this.state.perPage,
            "search": this.state.search
        };
        console.log(params);
        http.post('groupIdea/getMyTeamList', params)
            .then((response) => {
                if (!response.data.total_count == 0) {
                    this.state.userList = response.data.data;
                    this.state.userList = this.state.userList.filter(item => item.user_id != data.user_id);
                    console.log("userlist", response.data.data);
                    this.state.totalCompany = parseInt(response.data.total_count);
                    var total_pages = Math.ceil(this.state.totalCompany / this.state.perPage);
                    if (this.state.totalCompany > this.state.perPage) {
                        this.state.actualTotalPage = total_pages;

                        this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                        var newPaginationItems = [];
                        newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                            <Pagination.Prev onClick={this.previousPage} />);
                        for (let number = 1; number <= this.state.totalPages; number++) {
                            newPaginationItems.push(
                                <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                                    {number}
                                </Pagination.Item>,
                            );
                        }
                        newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                            <Pagination.Last onClick={this.lastPage} />);
                        this.state.paginationIteams = newPaginationItems;
                    }
                    this.state.noDataFound = false;
                } else {
                    this.state.paginationIteams = [];
                    this.state.userList = [];
                    this.state.noDataFound = true;
                }
                this.setState({ isActive: false });
            });
    }

    handlePageClick(event) {
        console.log(event);
        this.state.currentPage = parseInt(event.target.id);
        this.state.paginationIteams = [];
        this.setState({ isActive: true });
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.setState({ isActive: true });
            this.callAPIOnPagination();
        }
    }

    handleSubmitCoolInnovationIdea() {
        var params = {
            users: this.state.selectedUsers
        }
        console.log('params', params);
        this.props.history.push({ pathname: '/cool-innovation', state: { params } });
    }

    handleSubmitIdea() {
        var picture_url = "";
        var data = CookiesService.get("userData");
        if(this.state.selectedUsers.length == 1) {
            picture_url = this.state.selectedUsers[0].picture_url;
         }
        var designationArray = this.state.selectedUsers.map((user) => (user.designation)).toString();
        var params = {
            company_id: data.company_id,
            company_name: data.company_name,
            picture_url: picture_url,
            users: this.state.selectedUsers,
            designationArray: designationArray
        }
        // console.log('params', params);
        this.props.history.push({ pathname: '/send-idea', state: { params } })
        this.setState({ isActive: false });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    render() {
        const classes = {
            large: {
                width: "90px",
                height: "90px",
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
            buttondiv: {
                height: "70px",
            },
            buttonStyle: {
                backgroundColor: "#fe7500",
                color: "#fff",
                fontSize: 20,
                marginLeft: 10,
                textTransform: "none"
            },
            textOrenge: {
                color: "#fe7500"
            }
        }
        return (
            <div className='content-wrapper' >
                <SearchBar placeholder="Search Category by try typing 'Team'" onChangeValue={this.handleChangeSearch} ></SearchBar>
                { this.state.isActive ? <div style={{ position: "absolute", top: "150px", left: "50%" }} > <CircularProgress /></div> :
                    <>
                        <Grid container>
                            <Grid md={12}>
                                <Grid md={8}>
                                    {this.state.buttons ?
                                        <Grid md={8} style={{ margin: "auto" }}>
                                            <div>
                                                <Button variant="contained" size="large" style={classes.buttonStyle} onClick={this.handleSubmitCoolInnovationIdea}>Submit Cool Innovation</Button>
                                                <Button variant="contained" size="large" style={classes.buttonStyle} onClick={this.handleSubmitIdea}>Submit Idea</Button>
                                            </div>
                                        </Grid>
                                        : <div style={classes.buttondiv}></div>}

                                    {this.state.userList.map((item, index) => (
                                        <Box boxShadow={2} bgcolor='background.paper' m={1} p={1}>
                                            <Grid md={12} className='d-flex'>
                                                <Grid md={2}>
                                                    <Avatar alt='Remy Sharp'
                                                        src={item?.picture_url}
                                                        style={classes.large}></Avatar>
                                                </Grid>
                                                <Grid md={10}>
                                                    <h2>{item?.username}</h2>
                                                    <p style={classes.textOrenge}>{item?.designation}</p>
                                                </Grid>
                                                <Grid md={1}>
                                                    <Checkbox
                                                        style={classes.large}
                                                        icon={<CheckBoxOutlineBlankIcon style={classes.sizeIcon} />}
                                                        checkedIcon={<CheckBoxIcon style={classes.sizeIcon} />}
                                                        onClick={this.handleClick}
                                                        value={item.user_id}></Checkbox>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                    {this.state.paginationIteams &&
                                        <Grid className="d-flex">
                                            <Box justifyContent="center" style={{ margin: "auto" }}>
                                                <Pagination>{this.state.paginationIteams}</Pagination>
                                            </Box>
                                        </Grid>
                                    }
                                    {this.state.noDataFound == true && <NoDataFound message='No record found' icon='fa fa-search' />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </div >
        );
    }
}

export default withStyles(styles)(MyTeam);