import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import SignIn from "./components/Authentication/SignIn";
import Mainlayout from "./components/layout/Mainlayout";
import Dashboard from "./components/Dashboard";
import CookiesService from './services/CookiesService';
import LoginDetail from "./components/Authentication/LoginDetail";
import YoutubeStep from "./components/Authentication/YoutubeDetail";
import SelectCategoryLogin from "./components/Authentication/SelectCategory";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0
    };

    // var userData = JSON.parse(sessionStorage.getItem("userData"));
    var userData = CookiesService.get("userData");
    console.log("userdata in App", userData);
    if (userData != undefined && userData != null) {
      if (Object.keys(userData).length > 0) {
        this.state.user_id = userData.user_id;
      }
    }
  }

  render() {
    const { user_id } = this.state;
    if (this.state.user_id != 0) {
      return <Mainlayout />;
    }

    return (
      <div className='App'>
        {/* <Router>      
            <Route path="/mainlayout" component={Mainlayout}></Route>
         */}
        
        <Router>
            {user_id ? (
              <Redirect to='/dashboard' component={Dashboard} />
            ) : (
              <Redirect to='/' component={SignIn} />
              // <SignIn is_login={false}/>
            )}
        
          <Switch>
            <Route exact path='/' component={SignIn} />
            <Route path='/login' component={LoginDetail} />
            <Route path='/youtube-step' component={YoutubeStep} />
            <Route path='/select_categories'component={SelectCategoryLogin} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
