import React, { Component } from "react";
import { Box, Avatar, Grid, Button, InputLabel, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchBar from "../SearchBar";
import CircularProgress from '@material-ui/core/CircularProgress';
import http from "../../../../http-common";
import { withRouter } from 'react-router-dom';

class EmployeeListCoolInnovation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestParams: props.location.state,
            companyUserdata: [],
            selectedUsers: [],
            selectUser: [],
            totalUser: 0,
            isActive: true,
            buttons: false

        }
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.handleSubmitIdea = this.handleSubmitIdea.bind(this);
        this.state.selectedUsers = this.state.requestParams.selectedUserList;
        this.state.selectUser = this.state.selectedUsers.map((user) => (user.user_id));
        console.log("length", this.state.selectedUsers);
        if (this.state.selectUser.length) {
            this.state.buttons = true;
        }
    }

    handleChangeSearch(newValue) {
        this.state.search = newValue;
        this.state.isActive = true;
        this.callAPIOnPagination();
    }

    handleSubmitIdea() {
        var params = {
            users: this.state.selectedUsers
        }
        console.log('params', params);
        this.props.history.push({ pathname: '/cool-innovation', state: { params } });
    }

    handleClick(e) {
        if (e.target.checked) {
            if (!this.state.selectUser.includes(e.target.value)) {
                this.state.selectUser = this.state.selectUser.concat(e.target.value);
                const selectedUsers = this.state.companyUserdata.filter(item => item.user_id == e.target.value);
                selectedUsers[0]['company_id'] = this.state.requestParams.company_id;
                selectedUsers[0]['is_checked'] = true;
                selectedUsers[0]['designation'] = selectedUsers[0].metavalues[0].meta_value;
                this.state.selectedUsers = this.state.selectedUsers.concat(selectedUsers);
                console.log('state Selected User', this.state.selectedUsers);
            }
            this.setState({ buttons: true });
        }
        else {
            if (this.state.selectUser.includes(e.target.value)) {
                const selectedUsers = this.state.selectedUsers.filter(item => item.user_id != e.target.value);
                this.state.selectedUsers = selectedUsers;
                const users = this.state.selectUser.filter(item => item != e.target.value);
                this.state.selectUser = users;
                e.target.checked = false;
            }
        }
        if (this.state.selectUser.length == 0) {
            this.setState({ buttons: false });
        } else {
            this.setState({ buttons: true });
        }
    }

    callAPIOnPagination() {
        http.get(`api/companymembersBycompanyId?comapny_id=${this.state.requestParams.company_id}`)
            .then(response => {
                this.state.companyUserdata = response.data.company_user_data;
                this.setState({ isActive: false });
            });
    }



    componentDidMount() {
        this.callAPIOnPagination();
    }

    render() {
        const classes = {
            large: {
                width: "90px",
                height: "90px",
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
            buttondiv: {
                height: "50px",
            },
            buttonStyle: {
                backgroundColor: "#fe7500",
                color: "#fff",
                fontSize: 20,
                marginLeft: 10,
                textTransform: "none"
            },
            textOrenge: {
                color: "#fe7500"
            },
            Avatarsize: {
                width: "80px",
                height: "80px",
                fontSize: 40
            }
        }
        return (
            <div className='content-wrapper' >
                <SearchBar placeholder="Search User" onChangeValue={this.handleChangeSearch} ></SearchBar>
                { this.state.isActive ? <div style={{ position: "absolute", top: "150px", left: "50%" }} > <CircularProgress /></div> :
                    <>
                        <Grid container>
                            <Grid md={12}>
                                <Grid md={8}>
                                    {this.state.buttons ?
                                        <Grid md={4} style={{ margin: "auto" }}>
                                            <div style={classes.buttondiv}>
                                                <Button variant="contained" size="large" style={classes.buttonStyle} onClick={this.handleSubmitIdea}>Submit Idea</Button>
                                            </div>
                                        </Grid>
                                        : <div style={classes.buttondiv}></div>}

                                    {this.state.companyUserdata?.map((item, index) => (
                                        <Box boxShadow={2} bgcolor='background.paper' m={1} p={1} key={index}>
                                            <Grid md={12} className='d-flex'>
                                                <Grid md={2}>
                                                    <Avatar alt='Remy Sharp' style={classes.Avatarsize}>{item?.metavalues[0].meta_value.charAt(0)}</Avatar>
                                                </Grid>
                                                <Grid md={8}>
                                                    <h2>{item?.metavalues[0].meta_value}</h2>
                                                </Grid>
                                                <Grid md={2}>
                                                    <Checkbox
                                                        style={classes.large}
                                                        checked={this.state.selectUser.includes(item.user_id)}
                                                        icon={<CheckBoxOutlineBlankIcon style={classes.sizeIcon} />}
                                                        checkedIcon={<CheckBoxIcon style={classes.sizeIcon} />}
                                                        onClick={this.handleClick}
                                                        value={item.user_id}></Checkbox>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </div >
        );
    }
}

export default withRouter(EmployeeListCoolInnovation);