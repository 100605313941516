import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import http from "../../http-common";
import CookiesService from '../../services/CookiesService';
import { Avatar, Grid } from "@material-ui/core";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      activeLink: {},
      appMenubar: [
        {
          id: 1,
          redirectTo: "/dashboard",
          icon: "fa fa-home",
          menuName: "Home"
        },
        {
          id: 2,
          redirectTo: "/recommend-engine",
          icon: "fa fa-search",
          menuName: "Recommendations Engine"
        },
        {
          id: 3,
          redirectTo: "/cool-innovation",
          icon: "fa fa-comments",
          menuName: "Submit Cool Innovation"
        },
        {
          id: 4,
          redirectTo: "/myteam",
          icon: "fa fa-users",
          menuName: "My Team"
        },
        {
          id: 5,
          redirectTo: "/post-problem",
          icon: "fa fa-pencil",
          menuName: "Post Problem"
        },
        {
          id: 6,
          redirectTo: "/problem_statement",
          icon: "fa fa-comments",
          menuName: "Problem Statements"
        },
        {
          id: 7,
          redirectTo: "/submit-idea",
          icon: "fa fa-lightbulb-o",
          menuName: "Submit Idea"
        },
        {
          id: 8,
          redirectTo: "/idea-received",
          icon: "fa fa-comments",
          menuName: "Ideas Received"
        },
        {
          id: 9,
          redirectTo: "/idea-submitted",
          icon: "fa fa-lightbulb-o",
          menuName: "Ideas Submitted"
        },
        {
          id: 10,
          redirectTo: "/profile",
          icon: "fa fa-user",
          menuName: "Profile"
        },
        {
          id: 11,
          redirectTo: "/preferences",
          icon: "fa fa-cog",
          menuName: "Preferences"
        },
        {
          id: 12,
          link: "https://blog.bizofit.com/the-startup-groove/",
          icon: "fa fa-play-circle",
          menuName: "Startup Tv"
        },
        {
          id: 13,
          link: "http://apps.company",
          icon: "fa fa-calculator",
          menuName: "App Estimate"
        }
        // {
        //   id: 15,
        //   redirectTo: "/",
        //   icon: "fa fa-sign-out",
        //   menuName: "Sign Out"
        // }
      ]
    };
    var userData = CookiesService.get("userData");
    // var userData = JSON.parse(sessionStorage.getItem("userData"));
    this.state.user_id = userData?.user_id;
    this.logout = this.logout.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  logout() {
    var params = {
      user_id: this.state.user_id
    };

    http.post("/api/logout", params).then(response => {
      console.log("hello");
      CookiesService.remove('userData');
      // sessionStorage.removeItem("userData");
      window.location = "/";
    });
  }
  handleClick(item) {
    this.setState({ activeLink: item });
  }
  render() {
    var userData = CookiesService.get("userData");
    const classes = {
      large: {
        width: '55px',
        height: '55px',
      },
      userAvtar: {
        color: '#fff',
        marginLeft: '4px',
        wordBreak: 'break-all'
      }
    }
    return (
      <div>
        <aside class='main-sidebar'>
          {/* <!-- sidebar: style can be found in sidebar.less --> */}
          <section class='sidebar'>
            {/* <!-- search form (Optional) --> */}
            <Grid class='sidebar-form'>
              <Grid className='d-flex'>
                <Grid>
                  <Avatar style={classes.large} src={userData.picture_url ? userData.picture_url : '../assets/images/user5.jpg'}></Avatar>
                </Grid>
                <Grid style={classes.userAvtar}>
                  <div>
                    <span>{userData.username}</span><br />
                    <span>{userData.email}</span><br />
                    <span>{userData.company_name}</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <hr></hr>
            {/* <!-- Sidebar Menu --> */}
            <ul class='sidebar-menu' data-widget='tree'>
              {this.state.appMenubar.map((item, index) => {
                if (item.link) {
                  return (<li
                    onClick={() => this.handleClick(item)}
                    className={
                      item?.id === this.state?.activeLink?.id ? "activeclass" : ""
                    }>
                    <a href={item?.link} target="_blank">
                      <i class={item?.icon}></i> <span>{item?.menuName}</span>
                    </a>
                  </li>);
                } else {
                  return (<li
                    onClick={() => this.handleClick(item)}
                    className={
                      item?.redirectTo === window.location.pathname ? "activeclass" : ""
                    }>

                    <Link to={item?.redirectTo}>
                      <i class={item?.icon}></i> <span>{item?.menuName}</span>
                    </Link>
                  </li>);
                }

              })}
              <li onClick={() => this.logout()}>
                {" "}
                <Link to='/'>
                  <i class='fa fa-sign-out'></i> <span>Sign Out</span>
                </Link>
              </li>

              {/* <li><Link to="/idea_submit_new"><i class="fa fa-user"></i> <span>Ideasubmit_new</span></Link></li> */}
            </ul>
            {/* <!-- /.sidebar-menu --> */}
          </section>
          {/* <!-- /.sidebar --> */}
        </aside>
      </div >
    );
  }
}

export default Sidebar;
