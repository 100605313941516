import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Grid, Box, Avatar} from '@material-ui/core';
import http from "../http-common";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';
import { Card, CardText, CardBody, CardTitle } from 'reactstrap';
import YouTube from 'react-youtube';
import StarRatings from 'react-star-ratings';
import CookiesService from '../services/CookiesService';
import { IconButton } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ForumIcon from '@material-ui/icons/Forum';

class Idea_received extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_id: 0,
            ideas: [],
            ideaDetail: {},
            isIdeaDetail: false,
            isActive: true,
            ideas_received_count: "0",
            currentPage: 1,
            perPage: 10,
            totalPages: 0,
            paginationIteams: [],
            actualTotalPage: 0,
            selectedTab: 0,
            success: '',
            error: '',
            file_name: '',
            file_data: '',
            invalidImage: '',
            postMessageIdeaId: 0,
            postMessageDescription: '',
        };
        var userData = CookiesService.get("userData");
        this.reader = new FileReader();
        this.state.user_id = userData.user_id;
        this.handlePageClick = this.handlePageClick.bind(this);
        this.callAPIOnPagination = this.callAPIOnPagination.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.forwardIdea = this.forwardIdea.bind(this);
        this.tabChange = this.tabChange.bind(this);
        this.LikeDislikeIdea = this.LikeDislikeIdea.bind(this);
        this.DeleteIdea = this.DeleteIdea.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.forwardToSubmitIdea = this.forwardToSubmitIdea.bind(this);
        this.handlePostMessage = this.handlePostMessage.bind(this);
        this.fileValidate = this.fileValidate.bind(this);
        this.handlePostMessageDescription = this.handlePostMessageDescription.bind(this);
        this.submitPostMessage = this.submitPostMessage.bind(this);
        this.ideaDetailApi = this.ideaDetailApi.bind(this);
    }

    ideaDetailApi(ideaId){
        this.setState({isActive: true});
        http.get(`/api/companyInteractionAction?idea_id=${ideaId}&user_id=${this.state.user_id}`)
            .then((response) => {
                
                this.state.ideaDetail = response.data;
                this.state.isIdeaDetail = true;
                console.log('ideadetail',this.state.ideaDetail);
                this.setState({isActive: false});
            });
    }

    handlePostMessageDescription(event) {
        this.state.postMessageDescription = event.target.value;
    }

    submitPostMessage() {
        this.setState({isActive: true});
        var param = {
            idea_id: this.state.postMessageIdeaId,
            message: this.state.postMessageDescription,
            user_id: this.state.user_id,
            file_name: this.state.file_name,
            file_data: this.state.file_data,
        }
        console.log('post message param', param);
        http.post("/api/ideaInteraction_ios", param)
            .then((response) => {
                console.log('post message responce', response);
                
                if(response.data.status == "success") {
                    this.setState({isActive: false});
                    this.ideaDetailApi(this.state.postMessageIdeaId);
                    this.setState({ success: response.data.message }, () => {
                        window.setTimeout(() => {
                            this.setState({ success: '' });
                        }, 3000)
                    });
                }
            });
    }

    handlePostMessage(ideaId) {
        this.state.postMessageIdeaId = ideaId;
        console.log('postMessageIdeaId', this.state.postMessageIdeaId);
        this.setState({isPostMessage: true});
    }

    forwardToSubmitIdea() {
        this.props.history.push({ pathname: '', state: {}});
    }

    refreshPage() {
        this.state.currentPage = 1;
        this.setState({isActive: true});
        this.callAPIOnPagination();
    }

    LikeDislikeIdea(ideaID, status) {
        http.get(`/api/likeDislikeIdeaEmail?idea_id=${ideaID}&user_id=${this.state.user_id}&status=${status}`)
            .then((response) => {
                if(response.data = "Updated") {
                    if(status == 1) {
                        document.getElementById("like-button-"+ideaID).classList.add('fa-thumbs-up');
                        document.getElementById("like-button-"+ideaID).classList.remove('fa-thumbs-o-up');
                        document.getElementById("dislike-button-"+ideaID).classList.remove('fa-thumbs-down');
                        document.getElementById("dislike-button-"+ideaID).classList.add('fa-thumbs-o-down');
                    } else {
                        document.getElementById("dislike-button-"+ideaID).classList.add('fa-thumbs-down');
                        document.getElementById("dislike-button-"+ideaID).classList.remove('fa-thumbs-o-down');
                        document.getElementById("like-button-"+ideaID).classList.remove('fa-thumbs-up');
                        document.getElementById("like-button-"+ideaID).classList.add('fa-thumbs-o-up');
                    }
                }
            });
    }

    DeleteIdea(ideaID) {
        var param = {
            idea_id: ideaID,
            user_id: this.state.user_id
        }
        http.post("/api/deleteReceiverIdea", param)
            .then((response) => {
                if(response.data.code == 1) {
                    this.state.paginationIteams = [];
                    this.setState({isActive: true});
                    this.setState({ success: response.data.message }, () => {
                        window.setTimeout(() => {
                            this.setState({ success: '' });
                        }, 3000)
                    });
                    this.setState({isActive: true});
                    this.callAPIOnPagination();
                }
            });
    }

    tabChange(tab) {
        this.setState({ selectedTab: tab});
    }

    forwardIdea(ideaId) {
        const idea = this.state.ideas.filter((item) => (item.idea_id == ideaId));
        const parmas = {};
        if(idea[0].ideas[0].is_company_shared == 1){
            parmas.is_forward = 1;
            parmas.isCompanyShare = true;
            parmas.idea_id= idea[0].idea_id;
            const company = [
                {
                    ID: idea[0].ideas[0].company_id,
                    post_title: idea[0].ideas[0].share_company_name,
                    avg_rating: idea[0].ideas[0].company_avg_rating,
                    post_content: idea[0].ideas[0].company_description,
                    company_post_id: idea[0].ideas[0].company_post_id,
                    company_business_website: idea[0].ideas[0].company_business_website,
                    is_company_shared: '1'
                }
            ]
            parmas.company = company;
        }
        if(idea[0].ideas[0].is_product_shared == 1){
            parmas.is_forward = 1;
            parmas.isProductShare = true;
            parmas.idea_id= idea[0].idea_id;
            const product = [
                {
                    ID: idea[0].ideas[0].product_id,
                    attachment: idea[0].ideas[0].product_image,
                    post_title: idea[0].ideas[0].product_name,
                    price: idea[0].ideas[0].product_price,
                    post_content: idea[0].ideas[0].product_description,
                    is_product_shared: '1',
                }
            ]
            parmas.product = product;
        }
        console.log('idea', parmas);
        this.props.history.push({pathname: '/submit-idea', state: { parmas }});
    }

    handlePageClick(event) {
        console.log(event);
        //console.log(Number(event.target.id));
        this.state.currentPage = parseInt(event.target.id);
        //this.setState({currentPage:parseInt(event.target.id)});
        this.state.paginationIteams = [];
        this.callAPIOnPagination();
    }

    callAPIOnPagination() {
        var params = {
            user_id: this.state.user_id,
            page: this.state.currentPage,
            per_page: this.state.perPage
        }
        http.post("/api/getideasByuserIDAndRating", params)
            .then((response) => {
                this.state.ideas = response.data.user_ideas;
                console.log('user_ideas', this.state.ideas);
                console.log('this.state.ideas[0].idea_id', this.state.ideas[0].idea_id);
                this.state.ideas_received_count = parseInt(response.data.ideas_received_count);
                var total_pages = Math.ceil(this.state.ideas_received_count / this.state.perPage);

                this.state.actualTotalPage = total_pages;

                this.state.totalPages = (total_pages > 10) ? 10 : total_pages;

                var newPaginationItems = [];
                newPaginationItems.push(<Pagination.First onClick={this.firstPage} />,
                    <Pagination.Prev onClick={this.previousPage} />);
                for (let number = 1; number <= this.state.totalPages; number++) {
                    newPaginationItems.push(
                        <Pagination.Item key={number} active={number === this.state.currentPage} id={number} onClick={this.handlePageClick}>
                            {number}
                        </Pagination.Item>,
                    );
                }
                newPaginationItems.push(<Pagination.Next onClick={this.nextPage} />,
                    <Pagination.Last onClick={this.lastPage} />);

                this.setState({ paginationIteams: newPaginationItems });

                this.setState({ isActive: false });
                this.ideaDetailApi(this.state.ideas[0].idea_id);
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.callAPIOnPagination();
    }

    firstPage() {
        if (this.state.currentPage = 1) {
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    lastPage() {
        if (this.state.currentPage = this.state.actualTotalPage) {
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    nextPage() {
        if (this.state.currentPage < this.state.actualTotalPage) {
            this.state.currentPage = parseInt(this.state.currentPage) + 1;
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    previousPage() {
        if (this.state.currentPage > 1) {
            this.state.currentPage = parseInt(this.state.currentPage) - 1;
            this.state.paginationIteams = [];
            this.callAPIOnPagination();
        }
    }

    fileValidate(event) {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            this.setState({ invalidImage: 'Please select image.' });
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            this.setState({ invalidImage: 'Please select valid image.' });
            return false;
        }

        this.reader.readAsDataURL(imageFile);
        this.reader.onload = (e) => {
            this.state.file_name = imageFile.name;
            this.state.file_data = this.reader.result;
            this.setState({ invalidImage: null });
        };
    }

    render() {
        const opts = {
            height: '290px',
            width: '630px'
        };
        const classes = {
            buttonStyle: {
                color: '#fe7500',
                fontSize: '23px'
            },
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
            headerIcon: {
                marginLeft: '20px',
                cursor: 'pointer',
            },
            textOrenge: {
                color: "#fe7500",
                marginTop: "8px",
                position: 'absolute'
            },
            sizeIcon: {
                fontSize: 35,
                color: "#fe7500"
            },
            headerDiv: {float: 'right', fontSize: '25px',    display: 'flex',color: '#fe7500', position: 'absolute', top: '15px', right: '10px'}
        }

        return (
            <div>
                <LoadingOverlay
                    active={this.state.isActive}
                    spinner
                    text='Please wait...'
                >
                    <div class="content-wrapper">
                        <section class="content-header">
                            <h1> Idea Received </h1>
                            <ol class="breadcrumb">
                            <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
                                <li>Idea Received</li>
                            </ol>
                        </section>
                        {this.state.success && <div class="alert alert-success" role="alert" style={classes.widthFull}>{this.state.success}</div>}
                        {this.state.error && <div class="alert alert-danger" role="alert" style={classes.widthFull}>{this.state.error}</div>}
                        {/* <!-- Main content --> */}
                        <Tabs selectedIndex={this.state.selectedTab}>
                            <section class="content container-fluid">
                                <div id="proinner-page" class="idea-receivedpage">
                                        <ul class="resp-tabs-list hor_1 widget-user-2">
                                            {console.log(this.state.ideas)}
                                            {this.state.ideas.map((idea, index) => 
                                                <li style={{width: '100%'}}>
                                                    <Box boxShadow={3} borderRadius={5} p={2} onClick={this.ideaDetailApi.bind('ideaId', idea.idea_id)}>
                                                    <div class="widget-user-image"> <img class="img-circle" src={idea.ideas[0].picture_url} alt="User Avatar" /></div>
                                                    {idea.is_new == 1 && <div style={{float: 'right'}}><span style={{color: '#fff', background: 'red', borderRadius: '3px', padding: '3px'}}>New</span></div>}
                                                    <h3 class="widget-user-username">{idea.ideas[0].username}</h3>
                                                    <h5 class="widget-user-desc">{idea.ideas[0].designation}</h5>
                                                    <h5 class="widget-user-desc">{idea.ideas[0].idea_title}</h5>
                                                    <div class="time"> <span class="fa fa-clock-o"></span>{moment.unix(idea.ideas[0].date_created).format("DD-MMM-YYYY hh:mm:s")} </div>
                                                    <ul style={{display: 'flex'}}>
                                                        <li><IconButton style={classes.buttonStyle} onClick={this.LikeDislikeIdea.bind('ideaId', idea.idea_id).bind('status', 1)}><i class="fa fa-thumbs-o-up" id={'like-button-'+idea.idea_id}></i></IconButton></li>
                                                        <li><IconButton style={classes.buttonStyle} onClick={this.LikeDislikeIdea.bind('ideaId', idea.idea_id).bind('status', 0)}><i class="fa fa-thumbs-o-down" id={'dislike-button-'+idea.idea_id}></i></IconButton></li>
                                                        <li><IconButton style={classes.buttonStyle} onClick={this.forwardIdea.bind('ideaId', idea.idea_id)}><i class="fa fa-share"></i></IconButton></li>
                                                        <li><IconButton style={classes.buttonStyle} onClick={this.handlePostMessage.bind('ideaId', idea.idea_id)} data-toggle="modal" data-target="#post-message"><i class="fa fa-reply"></i></IconButton></li>
                                                        <li><IconButton style={classes.buttonStyle} onClick={this.DeleteIdea.bind('ideaId', idea.idea_id)}><i class="fa fa-trash-o"></i></IconButton></li>
                                                    </ul>
                                                    </Box>
                                                </li>)}
                                        </ul>

                                    <div class="resp-tabs-container hor_1 widget-user-2">
                                        {this.state.isIdeaDetail == true ? 
                                            <Box p={1}>
                                            <div>
                                                <div class="widget-user-header pos-relative">
                                                    <div style={{float: 'right', fontSize: '20px', color: '#ff7300', cursor: 'pointer'}}>
                                                        <span data-toggle="modal" data-target="#post-message" class="fa fa-pencil edit-idea" onClick={this.handlePostMessage.bind('ideaId', this.state.ideaDetail.idea.idea_id)}></span>
                                                        <span class="fa fa-comments edit-idea" onClick={this.handlePostMessage} style={{marginLeft: '10px'}}></span>
                                                    </div>
                                                    <div class="widget-user-image">
                                                        <img class="img-circle" src={this.state.ideaDetail.idea.picture_url} alt="User Avatar" />
                                                    </div>
                                                    <h3 class="widget-user-username">{this.state.ideaDetail.idea.username}</h3>
                                                    <h5 class="widget-user-desc">{this.state.ideaDetail.idea.designation}</h5>
                                                    <h5 class="widget-user-desc">{this.state.ideaDetail.idea.idea_title}</h5>
                                                    <div class="time"> <span class="fa fa-clock-o"></span> {moment.unix(this.state.ideaDetail.idea.date_created).format("DD-MMM-YYYY hh:mm:s")} </div>
                                                    
                                                </div>
                                                <div class="box-body">
                                                    <p>{this.state.ideaDetail.idea.description}</p>
                                                </div>
                                            </div>
                                            <div>
                                                {this.state.ideaDetail.idea.is_idea_recommended == "1" ? (
                                                     <>
                                                     <Grid md={12}>
                                                         <Box boxShadow={5} p={2} m={2} borderRadius={10} onClick={() => { this.props.history.push({ pathname: '/company-detail', state: { post_id: this.state.ideaDetail.idea.company_post_id } }) }}>
                                                             <Grid><h3>{this.state.ideaDetail.idea.share_company_name}</h3></Grid>
                                                             <Grid>
                                                                 <StarRatings
                                                                     rating={parseFloat(this.state.ideaDetail.idea.company_avg_rating)}
                                                                     starRatedColor="#f28a16"
                                                                     starDimension="20px"
                                                                     numberOfStars={5}
                                                                     name=''
                                                                 />
                                                             </Grid>
                                                             <Grid><p>{this.state.ideaDetail.idea.company_description.substr(0, 280)+'...'}</p></Grid>
                                                         </Box>
                                                     </Grid>
                                                     </>
                                                ) : null
                                                }
                                            </div>
                                            {this.state.ideaDetail.idea.is_company_shared == "1" ? (
                                                    <>
                                                    <Grid md={12}>
                                                        <Box boxShadow={5} p={2} m={2} borderRadius={10} onClick={() => { this.props.history.push({ pathname: '/', state: { post_id: this.state.ideaDetail.idea.company_post_id } }) }}>
                                                            {/* <h4>{idea.receiver[0].receiver_data[0].username}</h4> */}
                                                            <Grid><h3>{this.state.ideaDetail.idea.share_company_name}</h3></Grid>
                                                            <Grid>
                                                                <StarRatings
                                                                    rating={parseFloat(this.state.ideaDetail.idea.company_avg_rating)}
                                                                    starRatedColor="#f28a16"
                                                                    starDimension="20px"
                                                                    numberOfStars={5}
                                                                    name=''
                                                                />
                                                            </Grid>
                                                            <Grid><p>{this.state.ideaDetail.idea.company_description.substr(0, 280)+'...'}</p></Grid>
                                                        </Box>
                                                    </Grid>
                                                    </>
                                                ) : null
                                                }
                                            {this.state.ideaDetail.idea.is_product_shared == "1" ? (
                                                    <>
                                                    <Grid md={12}>
                                                        <Box boxShadow={5} p={2} m={2} borderRadius={10} onClick={() => { this.props.history.push({ pathname: '/product-detail', state: { product_id: this.state.ideaDetail.idea.product_id } }) }}>
                                                            {/* <h4>{idea.receiver[0].receiver_data[0].username}</h4> */}
                                                            <Grid className='d-flex'>
                                                                <Grid md={2}>
                                                                    <Box mt={2} pr={1}>
                                                                        <img src={this.state.ideaDetail.idea.product_image} width="100%" />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid md={10}>
                                                                    <h3>{this.state.ideaDetail.idea.product_name}</h3>
                                                                    <p>{this.state.ideaDetail.idea.product_description.substr(0, 280)+ '...'}</p>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                    </>
                                                ) : null
                                                }
                                            {this.state.ideaDetail.idea.is_youtube_data == 1 ?
                                                <>
                                                <Box boxShadow={5} p={2} m={2} borderRadius={10}>
                                                    <Grid className='d-flex'>
                                                        <Grid md={3}>
                                                        <img height="100px" width="150px" src={this.state.ideaDetail.idea.youtube_data.image} alt="Card image cap" />
                                                        </Grid>
                                                        <Grid md={8}>
                                                            <h3>{this.state.ideaDetail.idea.youtube_data.title}</h3>
                                                            <p>{this.state.ideaDetail.idea.youtube_data.description.substr(0, 250) + "..."}</p>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid>
                                                    <YouTube
                                                            videoId={this.state.ideaDetail.idea.youtube_data.video_id}                  // defaults -> null
                                                            // id={string}                       // defaults -> null
                                                            // className={string}                // defaults -> null
                                                            // containerClassName={string}       // defaults -> ''
                                                            opts={opts}                        // defaults -> {}
                                                        // onReady={func}                    // defaults -> noop
                                                        // onPlay={func}                     // defaults -> noop
                                                        // onPause={func}                    // defaults -> noop
                                                        // onEnd={func}                      // defaults -> noop
                                                        // onError={func}                    // defaults -> noop
                                                        // onStateChange={func}              // defaults -> noop
                                                        // onPlaybackRateChange={func}       // defaults -> noop
                                                        // onPlaybackQualityChange={func}    // defaults -> noop
                                                        />
                                                    </Grid>
                                                </Box>
                                                    </>
                                                : null}

                                                {this.state.ideaDetail.messages.length !== 0 ? 
                                                <>
                                                <h3 style={{color: '#ff7300', marginLeft: '20px'}}>Messages:</h3>
                                                {this.state.ideaDetail.messages.map((message, index) => (<Box boxShadow={5} p={2} m={2} borderRadius={10} key={index}>
                                                    <Grid className='d-flex'>
                                                    <Grid md={3}><ForumIcon style={{color: '#ff7300', fontSize: '65px'}}/></Grid>
                                                    <Grid md={12}>
                                                        <p>From <b>{message.from_user == this.state.user_id ? 'me' : message.from_username}</b> to <b>{message.to_user == this.state.user_id ? 'me' : message.to_username}</b></p>
                                                        <p>Message: <span>{message.message}</span></p>
                                                        <p>Test</p>
                                                        <p>{moment.unix(message.date).format("DD-MMM-YYYY hh:mm:s")}</p>
                                                    </Grid>
                                                    </Grid>
                                                </Box>))}
                                                </>
                                                : null}
                                        </Box> : null }
                                    </div>
                                </div>
                            </section>
                        </Tabs>

                        <Grid style={{position: 'relative', left: '15%'}}>
                            <Pagination>{this.state.paginationIteams}</Pagination>
                        </Grid>

                        {/* <!-- /.content --> */}
                        {/* // <!-- Submit Model --> */}
                    {this.state.isPostMessage && <div class="modal fade post-modal" id="post-message">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <div> Post a Message </div>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
                                </div>
                                <div class="form">
                                    <div class="form-group margin-bottom">
                                        <textarea class="form-control" placeholder="Post a message" name="postMessageDescription" onChange={this.handlePostMessageDescription}></textarea>
                                    </div>
                                    <Grid className='d-flex' md={12}>
                                        <Grid md={6}>
                                    <label for="attachment" style={classes.arrow}>
                                        <AttachFileIcon style={classes.sizeIcon}></AttachFileIcon><span style={classes.textOrenge}>attachment</span>
                                        {this.state.invalidImage && <p style={classes.errorMessage}>{this.state.invalidImage}</p>}
                                        <input type="file" name="attachment" id="attachment" style={{ display: "none" }} accept="image/png,image/jpg,image/jpeg" onChange={this.fileValidate} />
                                    </label>
                                    <div>{this.state.file_data && <img src={this.state.file_data} height='100' width='100' />}</div>
                                    </Grid>
                                    <Grid md={6}>
                                        <p class="text-center"> <button type="button" class="btn send-btn" data-dismiss="modal" aria-label="Close" onClick={this.submitPostMessage}>Send</button></p>
                                    </Grid>
                                    
                                    </Grid>
                                </div>

                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>}
                        {/* <!-- /.submit-model --> */}

                    </div>
                </LoadingOverlay>
            </div>
        )
    }
}

export default Idea_received;