import { Box, Avatar, Grid, Button, InputLabel } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForward';
import StarRatings from 'react-star-ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faThumbsUp, faThumbsDown, faReply } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay";
import http from "../../../../http-common";
import Rating from "@material-ui/lab/Rating";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    tabOrange: {
        backgroundColor: "#fe7500",
        padding: "2px 10px",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "15px"
    },
    colorOrange: {
        color: "#fe7500",
    },
    fontItalic: {
        fontStyle: 'italic',
        color: '#606060'
    },
    textCenter: {
        textAlign: "center",
        color: "#fe7500",
        margin: "auto",
    }
}));

export default function ProblemStatementsDetail(props) {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(true);
    const [requestParms] = useState(props.location.state);
    const [problemDetails, setProblemDetails] = useState([]);
    const [problemIdeaDetails, setProblemIdeaDetails] = useState([]);
    // console.log("problemId ", requestParms.problem_id, requestParms.user_id);

    useEffect(() => {
        http.get(`api/problemIdeaDetails_new?problem_id=${requestParms.problem_id}&user_id=${requestParms.user_id}`).then(response => {
            // console.log("Problem Statements detail", response?.data?.problem_details[0].picture_url);
            console.log("Problem Statements Solution", response?.data?.problem_idea_details);

            setProblemDetails(response?.data?.problem_details[0]);
            setProblemIdeaDetails(response?.data?.problem_idea_details);
            setIsActive(false);
        });
    }, []);
    return (
        <div className='content-wrapper'>
            <LoadingOverlay
                active={isActive}
                spinner
                text='Please wait...'
            >
                <Grid container>
                    <Grid md={12}>
                        <Box boxShadow={0} bgcolor='background.paper' m={1} p={1}>
                            <Grid md={12} className='d-flex'>
                                <Grid style={{ margin: "auto" }}>
                                    <Avatar
                                        alt='Remy Sharp'
                                        src={problemDetails?.picture_url}
                                        className={classes.large}
                                    />
                                </Grid>
                                <Grid md={11}>
                                    <h3>{problemDetails?.username}</h3>
                                    <h4>{problemDetails?.designation}</h4>
                                    <p>{problemDetails?.company_name}</p>
                                    <sapn className={classes.tabOrange}>{problemDetails?.category}</sapn>
                                    <p><span className={classes.colorOrange}>Problem Posted:</span> <span className={classes.fontItalic}>{moment.unix(problemDetails?.date_created).format("DD-MMM-YYYY hh:mm:s")}</span></p>
                                </Grid>
                            </Grid>
                            <Grid md={12} className='d-flex'>
                                <Grid md={1}></Grid>
                                <Grid md={8}>
                                    <div>{problemDetails?.description}</div>
                                    <p><span className={classes.colorOrange}>DadeLine: </span><span>{moment(problemDetails?.deadline_date).format("DD-MMM-YYYY")}</span></p>
                                </Grid>
                            </Grid>
                            <Grid className='d-flex'>
                                <Grid md={1}></Grid>
                                <Grid md={2}>
                                    <h3 className={classes.colorOrange}>Ideas/Solutions:</h3>
                                </Grid>
                            </Grid>
                            <Grid className='d-flex'>
                                <Grid md={10}>
                                    {problemIdeaDetails?.map((item, index) => (
                                        <Box boxShadow={2} bgcolor='background.paper' m={1} p={1} borderRadius={10}>
                                            <Grid md={12} className='d-flex'>
                                                <Grid style={{ margin: "auto" }} md={1}>
                                                    <Avatar
                                                        alt='Remy Sharp'
                                                        src={item?.ideas[0].picture_url}
                                                        className={classes.large}
                                                    />
                                                </Grid>
                                                <Grid md={8}>
                                                    <h3>{item?.ideas[0].username}</h3>
                                                    <h4>{item?.ideas[0].designation}{" "}At{" "}{item?.ideas[0].company_name}</h4>
                                                    <p><span>{moment.unix(item?.ideas[0].date_created).format("DD-MMM-YYYY hh:mm:s")}</span></p>
                                                    <p><span>{item?.ideas[0].description}</span></p>
                                                </Grid>
                                                <Grid md={1} style={{ margin: 'auto' }}>
                                                    <div className='icon-wrap'>
                                                        <ArrowForwardIosIcon fontSize="large" style={{ color: "#606060" }} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Box boxShadow={2} bgcolor='background.paper' m={1} p={1} borderRadius={8}>
                                                <Grid md={12}>
                                                    <h3>{item?.ideas[0].share_company_name}</h3>
                                                    <Rating name="half-rating-read" value={parseFloat(item?.ideas[0].company_avg_rating)} precision={0.5} readOnly />
                                                    {/* <StarRatings
                                                        rating={parseFloat(item?.ideas[0].company_avg_rating)}
                                                        starRatedColor="#f28a16"
                                                        starDimension="20px"
                                                        numberOfStars={5}
                                                        name=''
                                                    /> */}
                                                    <div>{item?.ideas[0].company_description.substr(0, 250) + "..."}</div>
                                                </Grid>
                                            </Box>
                                            <Grid md={12} className='d-flex'>
                                                <Grid md={3} className={classes.textCenter}>
                                                    <FontAwesomeIcon icon={faThumbsUp} />
                                                    <span>{" "}{item?.like_count}</span>
                                                </Grid>
                                                <Grid md={3} className={classes.textCenter}>
                                                    <FontAwesomeIcon icon={faThumbsDown} />
                                                    <span>{" "}{item?.dis_like_count}</span>
                                                </Grid>
                                                <Grid md={3} className={classes.textCenter}>
                                                    <FontAwesomeIcon icon={faReply} />
                                                    <span>{" "}{item?.forword}</span>
                                                </Grid>
                                                <Grid md={3} className={classes.textCenter}>
                                                    <FontAwesomeIcon icon={faShare} />
                                                    <span>{" "}{item?.replied_count}</span>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </LoadingOverlay>
        </div>
    );
}