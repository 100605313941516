import React, { Component } from "react";
import { TextField, Grid, Box, createMuiTheme, ThemeProvider, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import http from "../../../../http-common";
import LoadingOverlay from "react-loading-overlay";
import { Alert } from '@material-ui/lab';

const styles = {
    input1: {
        height: 50
    },
    input2: {
        width: "100%",
        fontSize: 25,
    },
    MuiInputLabel: {
        root: {
            fontSize: "25px"
        }
    },
    MuiAlert: {
        icon: {
            fontSize: 25
        }
    }
};

const theme = createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {
                fontSize: "60px",
                width: '-webkit-fill-available'
            }
        },
        MuiInputLabel: {
            shrink: {
                fontSize: "20px"
            },
            root: {
                fontSize: "25px"
            }
        }
    }
});

class AddOutsideUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            email: "",
            company_name: "",
            designation: "",
            firstname: "",
            lastname: "",
            isActive: false,
            company_id: 0,
            user_id: 0,
            isAddUser: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleChange = (e) => {
        this.state[e.target.name] = e.target.value;
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ isActive: true });
        var obj = {
            email: this.state.email,
            company_name: this.state.company_name,
            designation: this.state.designation,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
        };
        console.log(obj);
        http.post("/api/getCreateUser", obj)
            .then((response) => {
                console.log(response);
                if (response.data.code == 0) {
                    this.setState({ error: response.data.message }, () => {
                        window.setTimeout(() => {
                            this.setState({ error: '' });
                        }, 3000)
                    });
                    this.setState({ isActive: false });
                }
                else {
                    this.state.company_id = response.data.data.company_id;
                    this.state.user_id = response.data.data.user_id;
                    var users = [
                        {
                            user_id: this.state.user_id,
                            username: this.state.firstname + ' ' + this.state.lastname,
                            designation: this.state.designation
                        }
                    ]
                    var params = {
                        company_id: this.state.company_id,
                        company_name: this.state.company_name,
                        users: users,
                        picture_url: "",
                        designationArray: this.state.designation.toString(),
                        shareItem: this.props.location.state.shareItem,
                    }
                    this.props.history.push({ pathname: '/send-idea', state: { params } })
                    this.setState({ isActive: false });
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        const customClasses = {
            widthFull: {
                position: "reletive",
                fontSize: 15,
                width: '100%',
                top: 10,
                right: 5
            },
            formStyle: {
                position: 'absolute'
            },
            buttonStyle: {
                position: 'absolute',
                left: '50%',
                backgroundColor: "#fe7500",
                color: "#fff",
                fontSize: 20,
                marginLeft: 10,
                textTransform: "none"
            }
        }
        return (
            <>
                <div class="content-wrapper">
                    <LoadingOverlay
                        active={this.state.isActive}
                        spinner
                        text='Please wait...'
                    >
                        <Grid container>
                            {this.state.error && <Alert severity="error" style={customClasses.widthFull} >{this.state.error}</Alert>}
                            <Grid md={12} className='d-flex'>
                                <form style={customClasses.formStyle}>
                                    <Box justifyContent="center" ml={4}>
                                        <ThemeProvider theme={theme}>
                                            <Grid md={12} style={{ minWidth: "125%" }}>
                                                <Box m={2}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="First Name"
                                                        name="firstname"
                                                        InputProps={{ classes: { input: this.props.classes.input2 } }}
                                                        onChange={this.handleChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid md={12} style={{ minWidth: "125%" }}>
                                                <Box m={2}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="last Name"
                                                        name="lastname"
                                                        InputProps={{ classes: { input: this.props.classes.input2 } }}
                                                        onChange={this.handleChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid md={12} style={{ minWidth: "125%" }}>
                                                <Box m={2}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Email"
                                                        name="email"
                                                        InputProps={{ classes: { input: this.props.classes.input2 } }}
                                                        onChange={this.handleChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid md={12} style={{ minWidth: "125%" }}>
                                                <Box m={2}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Designation"
                                                        name="designation"
                                                        InputProps={{ classes: { input: this.props.classes.input2 } }}
                                                        onChange={this.handleChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid md={12} style={{ minWidth: "125%" }}>
                                                <Box m={2}>
                                                    <TextField
                                                        id="standard-basic"
                                                        label="Company Name"
                                                        name="company_name"
                                                        InputProps={{ classes: { input: this.props.classes.input2 } }}
                                                        onChange={this.handleChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid md={12} spacing={0} alignItems="center" justify="center">
                                                <Box m={2}>
                                                    <Button variant="contained" size="large" style={customClasses.buttonStyle} onClick={this.onSubmit}>Send</Button>
                                                </Box>
                                            </Grid>
                                        </ThemeProvider>
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    </LoadingOverlay>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(AddOutsideUser);