import { Grid, Box, Button} from "@material-ui/core";
import { Component } from "react";
import YouTube from "react-youtube";
import HeaderBizofit from "./HeaderBizofit";

class YoutubeStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            youtubeVideoID: '9wycQe-zlfQ'
        }
        this.handleSkip = this.handleSkip.bind(this);
    }
    
    handleSkip() {
        this.props.history.push({ pathname: '/select_categories', state: {  } });
    }

    render() {
        const classes = {
            buttonStyle: {
                backgroundColor: "#ff720a",
                color: "#ffffff",
                textTransform: 'none',
                top: '25%',
                fontSize: '18px'
            },
        }
        const opts = {
            height: '550px',
            width: '100%'
        };
        return (
            <>
            <Grid container justify="center">
                <Grid md={12}>
                    <Grid className='d-flex'>
                        <Grid md={11}>
                            <HeaderBizofit />
                        </Grid>
                        <Grid md={1} justify='center'>
                            <Button style={classes.buttonStyle} onClick={this.handleSkip}>Skip</Button>
                        </Grid>
                    </Grid>
                    <Grid md={12}>
                        <Box p={2}>
                            <YouTube
                                videoId={this.state.youtubeVideoID}                  // defaults -> null
                                // id={string}                       // defaults -> null
                                // className={string}                // defaults -> null
                                // containerClassName={string}       // defaults -> ''
                                opts={opts}                        // defaults -> {}
                            // onReady={func}                    // defaults -> noop
                            // onPlay={func}                     // defaults -> noop
                            // onPause={func}                    // defaults -> noop
                            // onEnd={func}                      // defaults -> noop
                            // onError={func}                    // defaults -> noop
                            // onStateChange={func}              // defaults -> noop
                            // onPlaybackRateChange={func}       // defaults -> noop
                            // onPlaybackQualityChange={func}    // defaults -> noop
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid> 
            </>
        );
    }
}

export default YoutubeStep;